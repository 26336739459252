var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-container",
    { attrs: { icon: "mdi-flash", title: "Geração de Ofertas" } },
    [
      _c("emc-alert-snackbar", {
        attrs: {
          show: _vm.showMessage,
          message: _vm.message,
          errors: _vm.errors,
          color: _vm.error ? "error" : "success"
        }
      }),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("sys-group-tree-view", {
                    attrs: { selected: _vm.selected, selectable: "" },
                    on: {
                      "update:selected": function($event) {
                        _vm.selected = $event
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mx-2",
                      attrs: {
                        loading: _vm.loading,
                        color: "primary",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.save()
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { dark: "" } }, [
                        _vm._v("mdi-flash")
                      ]),
                      _vm._v(" Gerar Ofertas ")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }